:root {
  --red: #e32649;
  --blue: #65c5b3;
  --yellow: #ffc630;
}

@-webkit-keyframes backgroundScroll {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.025, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes backgroundScroll {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.025, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-wrap {
  justify-content: center;
  /* height: 100vh; */
  width: 100vw;
  box-sizing: border-box;

  .navbar {
    position: sticky;
    top: 0;
    z-index: 1001;
  }

  .navbar-top-wrap {
    height: 24px;
    background-color: var(--blue);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 32px;

    .social-icons {
      display: flex;
      padding-right: 32px;

      & li {
        margin-left: 8px;
      }
    }
  }

  .navbar-wrap {
    height: 96px;
    display: grid;
    grid-template-columns: 2fr 6fr;
    box-shadow: 0px -9px 31px 1px var(--blue);
    background-color: rgba(255, 255, 255, 0.7);
    cursor: url("../src/assets/PEtextbgpointer.png"), pointer;

    .logo {
      height: 96px;
      display: flex;
      justify-content: center;
      align-items: center;

      .img-logo {
        display: flex;
        height: 96px;
        border-radius: 5px;
      }
    }

    .navlinks {
      display: flex;
      justify-content: flex-end;
      margin-right: 16px;
      align-items: center;
      padding-right: 32px;

      & li {
        cursor: pointer;
      }

      & a {
        width: 10px;
        color: var(--red);
        margin: 8px 16px;
        font-weight: bold;
      }

      .faqs {
        margin-left: 32px;
      }
    }
  }

  .footer-wrap {
    display: grid;
    grid-template-rows: 4fr 4fr 1fr;
    position: relative;
    left: 0;
    bottom: 0;
    height: 345px;
    width: 100%;
    /* min-width: 902px; */

    .footer-top {
      background-color: var(--blue);
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      padding: 47px 15% 47px 15%;

      #thanks-subscribed {
        display: flex;
        color: #fff;
        font-weight: bold;
        font-size: larger;
        width: 100%;
        justify-content: center;
      }

      .paragraph {
        display: flex;
        justify-content: flex-end;
        margin-top: 0;
        margin-bottom: 0;
        font-size: medium;
        color: #fff;
        align-items: center;

        & p {
          margin-top: 0;
          margin-right: 16px;
          min-width: 189px;
        }
      }

      .subscribe-form-wrap {
        display: flex;
        justify-content: flex-start;
        margin-top: 0;

        .subscribe-form {
          display: flex;
          flex-direction: column;
          width: 100%;
          min-width: 228px;
          row-gap: 16px;

          & label {
            width: 100%;
            border: none;

            & input {
              width: 97%;
              padding: 4px;
              font-size: smaller;
              border: none;
            }

            & input::placeholder {
              color: lightgrey;
              padding: 4px;
            }
          }

          & #subscribe-btn {
            border: 1px solid #fff;
            background-color: var(--blue);
            color: #fff;
            padding: 4px;
            width: 99%;
          }
        }
      }
    }

    .footer-middle {
      background-color: var(--blue);
      box-shadow: 0px -4px 12px -1px #000 inset;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      padding: 0px 15%;
      color: #fff;
      font-size: medium;

      .services-p {
        .services-ul li {
          list-style: circle;
        }
      }

      .visit-us {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 8px;

        .visit-us-p {
          margin: 0;
        }

        .visit-us-ul {
          display: flex;
          justify-content: flex-start;
          column-gap: 8px;
          padding: 0;
          margin: 0;
        }

        .visit-us-email {
          margin: 0;
        }
      }
    }

    .footer-bottom {
      background-color: #000;
      display: flex;
      justify-content: center;

      .copyright {
        color: var(--yellow);
        font-size: x-small;

        & a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }

  /* SpinningLogo.css */
  .spinning-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh; /* Adjust the height based on your design */
  }

  .spinning-logo {
    width: 100px; /* Adjust the width based on your design */
    height: auto;
    animation: spin 0.5s linear infinite; /* Add a spinning animation */
  }

  /* SpinningLogo.css */

  .home-wrap {
    /* min-width: 902px; */

    .home-header {
      background-image: url("./assets/matteo-di-iorio-VD-Vjc8VmRA-unsplash (2).jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      -webkit-animation: backgroundScroll 15s ease-in-out infinite;
      -webkit-animation-fill-mode: both;
      animation: backgroundScroll 15s ease-in-out infinite;
      animation-fill-mode: both;
      height: 80vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .header-p-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 5px;
        padding: 8px;
        margin-left: 64px;
        margin-right: 64px;
        margin-bottom: 32px;

        .header-p1 {
          text-align: center;
          font-size: xx-large;
          /* font-weight: bolder; */
          font-family: Amatic SC;
          font-weight: 500;
        }

        .header-p2 {
          text-align: center;
          width: fit-content;
          font-size: large;
          font-weight: 600;
        }
      }

      .header-learn-how {
        margin-top: 46px;

        #learn-how {
          border: 4px solid white;
          padding: 8px 16px;
          color: black;
          font-size: larger;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }

      .scroll-bottom-btn {
        position: relative;
        bottom: -102px;

        & .scroll-down-img {
          color: var(--blue);
          font-weight: bolder;
        }
      }
    }

    .home-middle {
      height: 249px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 25%;
      /* min-width: 604px; */

      & p {
        color: var(--red);
        text-align: center;
      }

      .home-middle-p1 {
        font-size: larger;
        font-weight: bold;
      }
    }

    .home-bottom {
      background: url("./assets/samantha-gades-unsplash2.jpg");
      min-height: 547px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      justify-items: center;
      /* min-width: 833px; */
      padding: 32px 8px;
      row-gap: 16px;

      & article {
        background-color: rgba(255, 255, 255, 0.5);
        height: 232px;
        width: 316px;
        border-radius: 5px;
        display: grid;
        grid-template-rows: 2fr 2fr 2fr 1fr;
        justify-content: center;
        align-items: center;
        padding: 8px 4px;
        row-gap: 4px;

        .services-icons {
          height: 55px;
          display: flex;
          justify-content: center;

          & img {
            height: inherit;
          }
        }

        .services-heading {
          /* border: 2px solid brown; */

          & p {
            text-align: center;
            /* color: white; */
            margin: 4px;
          }
        }

        .services-paragraph {
          /* border: 2px solid yellow; */

          & p {
            text-align: center;
            /* color: white; */
            margin: 4px;
            font-size: smaller;
          }
        }

        .services-learn-more {
          /* border: 1px solid black; */
          display: flex;
          justify-content: center;
          padding: 4px 4px 8px 4px;

          & a {
            color: black;
          }
        }
      }

      .first {
        background-color: rgba(0, 0, 0, 0.7);
      }

      #black {
        color: #000;
        font-size: smaller;
      }
    }
  }

  .services-wrap {
    .serv-0 {
      background-image: url("./assets/services1.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      margin-bottom: 4px;

      & h2 {
        color: white;
      }
    }

    .serv-02 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;

      .serv-1 {
        background-image: url("./assets/services2.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
      }

      .serv-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 32px 64px;
        background-color: #656c8d;

        & p {
          color: white;
        }
      }
    }

    .serv-3 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      row-gap: 32px;
      padding: 32px 64px;

      & p {
        text-align: center;
        font-weight: bold;
      }
    }

    .serv-4 {
      height: 343px;
      padding: 0 266px;

      .serv-4-div {
        background-image: url("./assets/services3.png");
        height: inherit;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        border-radius: 16px;
        box-shadow: 1px 1px 63px 10px #4d4d4d;
        min-width: 480px;

        & p {
          margin-top: 100px;
          text-align: center;
          font-size: xx-large;
          color: white;
        }
      }
    }

    .serv-5 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 16px 64px;

      & h2 {
        width: 100%;
        text-align: center;
      }
    }

    .serv-6 {
      background-color: #e3e8fa;
      padding: 16px 64px;
      display: grid;
      grid-template-columns: 1fr 2fr;

      & p {
        font-weight: 450;
      }

      & ul {
        margin-bottom: 8px;

        & li {
          padding: 4px;

          & img {
            height: 10px;
            margin-right: 4px;
          }
        }
      }
    }

    .serv-7 {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .serv-7-1 {
        background-image: url("./assets/services4.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: inherit;
      }

      .serv-7-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 16px 32px;
        background-color: #665566;

        & p {
          color: white;

          /*Todo: put link in achor */
          & a {
            text-decoration: none;
            text-decoration-color: none;
            color: white;
          }
        }

        .p-small {
          font-size: small;
          padding-right: 72px;
          color: white;
        }
      }
    }
  }

  .blog-wrap {
    min-height: 85vh;

    #blog1 {
      margin-top: 32px;
    }

    .blog-0 {
      background-image: url("./assets/services1.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      margin-bottom: 4px;

      & h2 {
        color: white;
      }
    }

    .blog-1 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      & h3 {
        font-weight: bold;
        font-size: xx-large;
      }

      & img {
        /* height: 336px; */
        width: 50%;
        margin-top: 8px;
      }
    }

    .blog-2 {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin: 0 182px;

      .blog-2-heading {
        & h1 {
          margin-bottom: 0;
          cursor: pointer;
          transition: transform 0.3s ease;
          background: radial-gradient(
            circle at center,
            transparent 50%,
            var(--blue) 90%
          );
          border-radius: 5px;
        }

        & h1:hover {
          transform: scale(1.05);
          background: radial-gradient(
            circle at center,
            transparent 30%,
            var(--yellow) 50%
          );
        }

        & h2 {
          margin-top: 0;
          color: darkgray;
        }
      }

      .author-details {
        min-height: 92px;
        padding: 16px;
        padding-left: 40px;
        display: flex;
        column-gap: 8px;

        .author-image {
          background-image: url("./assets/blog/mazvita.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 86px;
          width: 86px;
          border-radius: 45px;

          .logo {
            background-image: url("./assets/blog/Logobg.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 26px;
            width: 26px;
            position: relative;
            top: 57px;
            left: 57px;
          }
        }
      }

      .author-blog {
        .author-name {
          & h4 {
            margin-top: 0;
          }
        }

        & ul {
          display: flex;
          padding: 0;

          & li {
            list-style-type: none;
            padding-left: 16px;
            margin-left: 16px;
            border-left: 1px solid #4d4d4d;
          }

          & :first-child {
            list-style-type: none;
            padding-left: 0;
            margin-left: 0px;
            border-left: none;
          }
        }
      }
    }

    .blogpost1 {
      margin: 0 182px;
      padding: 16px 0;
    }
  }

  .contact-wrap {
    min-height: 73vh;
    .contact-0 {
      background-image: url("./assets/services1.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      margin-bottom: 4px;

      & h2 {
        color: #fff;
      }
    }

    & p {
      padding: 8px 32px;
    }

    .contact-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 319px;
    }

    .contact-form {
      margin: 16px 32px;
      padding: 16px;
      row-gap: 16px;
      border: 2px solid var(--yellow);
      display: flex;
      flex-direction: column;

      .contact-names {
        display: grid;
        grid-template-columns: 1fr 1fr;

        #first-name {
          display: flex;
          flex-direction: column;
          margin: 8px;
          row-gap: 8px;

          #firstName {
            background-color: #eee;
            border: none;
            height: 32px;
            padding-left: 5px;
            width: 70%;
          }
        }

        #last-name {
          display: flex;
          flex-direction: column;
          margin: 8px;
          row-gap: 8px;

          #lastName {
            background-color: #eee;
            border: none;
            height: 32px;
            padding-left: 5px;
            width: 70%;
          }
        }
      }

      .contact-emails {
        display: grid;
        grid-template-columns: 1fr 1fr;

        #contact-email {
          display: flex;
          flex-direction: column;
          margin: 8px;
          row-gap: 8px;

          #email {
            background-color: #eee;
            border: none;
            height: 32px;
            padding-left: 5px;
            width: 70%;
          }
        }

        #contact-phone {
          margin: 8px;
          display: flex;
          flex-direction: column;
          row-gap: 8px;

          .phone-number-rest-wrap {
            display: flex;

            #countryCode {
              margin-right: 8px;
              background-color: #eee;
              border-color: #eee;
              height: 32px;
            }

            #phoneNumberRest {
              background-color: #eee;
              border: none;
              height: 32px;
              padding-left: 5px;
              width: 40%;
            }
          }
        }
      }

      .contact-options {
        margin: 8px;

        & label {
          padding-bottom: 8px;
        }

        & :nth-child(2) {
          margin-top: 8px;
        }
      }

      & textarea {
        resize: inherit;
        min-height: 82px;
        background-color: #eee;
        border: none;
      }

      .submit-button {
        display: flex;
        justify-content: flex-end;
        padding: 0 32px;
      }

      & button {
        border-color: var(--yellow);
        height: 32px;
        width: 112px;
      }
    }
  }

  .faq-wrap {
    .faq-0 {
      background-image: url("./assets/services1.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      margin-bottom: 4px;

      & h2 {
        color: #fff;
      }
    }

    .faq-container {
      margin: 16px 40px;
      min-height: 66vh;

      #plus-sign {
        cursor: pointer;
        margin-right: 8px;
      }

      #question {
        cursor: pointer;
      }
    }
  }

  .whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 7px 10px #999;
    z-index: 100;

    .whatsapp-icon {
      margin-top: 16px;
    }
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    text-align: center;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    box-shadow: 0 0 30px rgb(65 151 155 / 8);
    max-width: 50%;
    z-index: 2600;
    max-height: fit-content;
    border-radius: 67px;

    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 15px;
    }
  }

  #popup-img {
    max-width: 50%;
    height: auto;
  }

  #popup-button {
    background-color: var(--blue);
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    /* cursor: pointer; */
    cursor: url("../src/assets/Logobgpointer.png"), pointer;
    border-radius: 5px;
    font-weight: bold;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}

@media only screen and (max-width: 768px) {
  .app-wrap {
    font-size: 16px;
  }

  .spinning-logo {
    width: 50px; /* Adjust the width based on your design */
    height: auto;
    animation: spin 0.5s linear infinite; /* Add a spinning animation */
  }

  .app-wrap .popup {
    border-radius: 13px;
    padding: 8px;
  }

  .app-wrap .navbar-wrap .navlinks {
    display: none;
  }

  .app-wrap .navbar-wrap .logo {
    margin-left: 8px;
    margin-right: 23px;
    justify-content: space-between;
  }

  .app-wrap .navbar-wrap .logo .img-logo {
    height: 60px;
  }

  .hamburger {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
  }

  .navlinks-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 82vh;
    margin: 0;
    width: 101vw;
    padding: 0;

    & li {
      font-size: large;
      padding: 16px 0;
      font-weight: bold;
      border-bottom: 1px solid var(--yellow);

      & a {
        color: var(--red);
        font: bold;
      }
    }

    & img {
      margin-top: 60px;
    }
  }

  .app-wrap .navbar-top-wrap .social-icons {
    padding: 0;
  }

  .app-wrap .home-wrap .home-header .header-p-wrap .header-p1 {
    font-size: x-large;
  }

  .app-wrap .home-wrap .home-header .scroll-bottom-btn {
    position: relative;
    bottom: -73px;
  }

  .app-wrap .home-wrap .home-bottom {
    grid-template-columns: 1fr;
  }

  .app-wrap .footer-wrap .footer-middle .services-p {
    display: none;
  }

  .app-wrap .whatsapp_float {
    display: none;
  }

  .app-wrap .home-wrap .home-bottom & article {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .app-wrap .footer-wrap .footer-middle {
    grid-template-columns: 1fr;
    padding: 16px 15%;
  }

  #be-first {
    margin: 0;
    text-align: center;
  }

  .app-wrap .footer-wrap .footer-top {
    background-color: var(--blue);
    display: grid;
    grid-template-columns: 1fr;
  }

  /* services styling */
  #services-heading {
    font-size: small;
  }

  .app-wrap .services-wrap .serv-02 .serv-1 {
    height: 350px;
  }

  .app-wrap .services-wrap .serv-02 {
    grid-template-columns: 1fr;

    & p {
      text-align: center;
    }
  }

  .app-wrap .services-wrap .serv-4 {
    height: 343px;
    padding: 0;
  }

  .app-wrap .services-wrap .serv-4 .serv-4-div {
    padding: 0;
    min-width: 0;
    margin: 8px;

    & p {
      font-family: Amatic SC;
    }
  }

  .app-wrap .services-wrap .serv-5 {
    & p {
      text-align: center;
    }
  }

  .app-wrap .services-wrap .serv-6 {
    background-color: #e3e8fa;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr;

    & ul {
      & li {
        padding: 4px 0;
      }
    }
  }

  .app-wrap .services-wrap .serv-7 {
    grid-template-columns: 1fr;

    .serv-7-1 {
      height: 367px;
    }
  }

  .app-wrap .services-wrap .serv-7 .serv-7-2 {
    display: none;
  }

  /* services styling */

  /* blog styling*/

  .app-wrap .blog-wrap .blog-2 .blog-2-heading & h1 {
    background: radial-gradient(
      circle at center,
      transparent 50%,
      var(--blue) 90%
    );
    font-size: small;
  }

  #article1-h1 {
    font-size: large;
  }

  #article1-h2 {
    font-size: small;
  }

  .app-wrap .blog-wrap .blog-2 {
    margin: 0 16px;
  }

  .app-wrap .blog-wrap .blog-2 .author-details {
    min-height: 92px;
    padding: 4px 0px;
    column-gap: 0px;
    border-bottom: 1px solid var(--yellow);
    border-radius: 20px;

    & h4 {
      margin: 0px;
    }
  }

  .app-wrap .blog-wrap .blog-2 .author-details .author-image {
    height: 93px;
    width: 125px;
    border-radius: 45px;
  }

  .app-wrap .blog-wrap .blog-2 .author-blog .author-name {
    display: flex;
    justify-content: center;
  }

  .app-wrap .blog-wrap .blog-2 .author-blog & ul {
    margin: 4px 0px;
  }

  #blog-details-ul {
    & li {
      margin: 0px;
      padding: 0px;
      font-size: small;
      text-align: center;
    }
  }

  .app-wrap .blog-wrap .blogpost1 {
    margin: 0 21px;
    padding: 16px 0;
  }

  #blog-1-image {
    width: 90%;
  }

  /* blog styling */

  /* contact form styling  */

  .app-wrap .contact-wrap .contact-form .contact-names {
    display: grid;
    grid-template-columns: 1fr;
  }

  .app-wrap .contact-wrap .contact-form .contact-emails {
    display: grid;
    grid-template-columns: 1fr;
  }

  .app-wrap .contact-wrap .contact-form .contact-names #first-name #firstName {
    width: 90%;
  }

  .app-wrap .contact-wrap .contact-form .contact-names #last-name #lastName {
    width: 90%;
  }

  .app-wrap .contact-wrap .contact-form .contact-emails #contact-email #email {
    width: 90%;
  }

  .app-wrap
    .contact-wrap
    .contact-form
    .contact-emails
    #contact-phone
    .phone-number-rest-wrap
    #phoneNumberRest {
    width: 52%;
  }

  .app-wrap .contact-wrap .contact-form .contact-options {
    display: flex;
    flex-direction: column;
    margin: 8px 0px;
    row-gap: 4px;

    & div {
      font-size: small;
    }
  }

  .contact-wrap p {
    text-align: center;
  }
  /* contact form styling */

  /* faqs styling */

  .app-wrap .faq-wrap .faq-container {
    margin: 16px 16px;
  }

  /* faqs styling */

  /* subcribe form styling  */
  .app-wrap .footer-wrap .footer-top .subscribe-form-wrap {
    margin-top: 16px;
  }
  /* subcribe form styling  */
}

/* Small desktop styles */
@media only screen and (min-width: 769px) {
  .app-wrap {
    font-size: 20px;
  }

  .hamburger {
    display: none;
  }
}

/* Large desktop styles */
@media only screen and (min-width: 1201px) {
  .hamburger {
    display: none;
  }

  .app-wrap {
    font-size: 22px;
  }
}
